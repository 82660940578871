import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgOptimizedImage, CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { LangTranslateBarComponent } from '../shared/components/lang-translate-bar/lang-translate-bar.component';
import { KorviuLogoComponent } from '../shared/components/korviu-logo/korviu-logo.component';
import { KorviuButtonComponent } from '../shared/components/korviu-button/korviu-button.component';

@Component({
  selector: 'app-welcoming',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterModule,
    TranslateModule,
    FooterComponent,
    LangTranslateBarComponent,
    KorviuLogoComponent,
    KorviuButtonComponent
  ],
  templateUrl: './welcoming.component.html',
  styleUrl: './welcoming.component.scss'
})
export class WelcomingComponent {
  public get isPartnerWelcoming(): boolean {
    return this.router.url === '/auth/sign-up/partner-welcoming';
  }

  public get iswelcoming(): boolean {
    return this.router.url === '/welcoming';
  }

  constructor(private router: Router) {}
}
