<section class="max-w-full w-full h-full relative top-0 left-0 bg-wrapper">
  <div class="w-full h-full px-4 flex flex-col gap-8 text-center">
    <div class="py-16 flex items-center justify-end md:justify-center">
      <lang-translate-bar></lang-translate-bar>
    </div>
    <div class="w-full xl:pt-16 flex flex-col justify-start self-center items-center text-center max-w-2xl content">
      <div class="pb-8 xl:pb-24"><korviu-logo size="s" theme="white"></korviu-logo></div>
      @if (iswelcoming) {
        <div class="flex flex-col justify-evenly items-center gap-8 text pb-12">
          <h1 class="heading-xlarge lg:whitespace-nowrap outfit extra-bold">
            {{ 'welcoming.welcomeToUnigana' | translate }}
          </h1>
          <h2 class="paragraph-large outfit medium sub-header">{{ 'welcoming.growYour' | translate }}</h2>
          <div>
            <p class="paragraph-medium outfit regular sub-text">{{ 'welcoming.joinUs' | translate }}</p>
            <p class="paragraph-medium outfit regular sub-text">{{ 'welcoming.LetStart' | translate }}</p>
          </div>
        </div>
        <div class="flex flex-wrap md:flex-nowrap w-full justify-between items-center gap-4 md:gap-8">
          <korviu-button size="medium" routerLink="/auth/log-in" color="ghost" class="w-full">{{
            'auth.signinButton' | translate
          }}</korviu-button>
          <korviu-button size="medium" routerLink="/auth/sign-up" color="tertiary" class="w-full">{{
            'auth.signUpButton' | translate
          }}</korviu-button>
        </div>
      }
      @if (isPartnerWelcoming) {
        <div class="text flex flex-col gap-4 pb-12">
          <h1 class="heading-xlarge outfit extra-bold">{{ 'welcoming.welcomeToUnigana' | translate }}</h1>
          <p class="paragraph-medium outfit regular sub-text">{{ 'welcoming.partnerHeader' | translate }}</p>
          <p class="paragraph-medium outfit regular sub-text">{{ 'welcoming.partnerDescription' | translate }}</p>
        </div>
        <korviu-button routerLink="/auth/sign-up/partner" color="tertiary" class="w-full">{{
          'auth.signup.letsStart' | translate
        }}</korviu-button>
      }
    </div>
  </div>
  <div class="absolute w-full bottom-0"><unigana-footer></unigana-footer></div>
</section>
